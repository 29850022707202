<template>
  <!-- コンテンツ選択ダイアログ -->
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    width="100%"
    max-width="1200px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">コンテンツ選択</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="キーワード検索"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text class="scrollable-content">
        <v-data-table
          :headers="headers"
          :items="siteContents"
          :search="search"
          :items-per-page="50"
          :mobile-breakpoint="0"
          :footer-props="{
            'items-per-page-options': [50, 100, -1],
            'items-per-page-text': '表示件数',
          }"
          no-data-text="対応コンテンツがありません"
        >
          <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
            {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
              props.itemsLength
            }}件
          </template>
          <template v-slot:body="{ items: siteContents }"> <!-- eslint-disable-line -->
            <tbody>
              <tr
                v-for="siteContent in siteContents"
                :key="siteContent.index"
                :class="
                  `content_id_` +
                  siteContent.content_id +
                  ' ' +
                  siteContent.tr_class
                "
                @click="onClickList(siteContent)"
              >
                <td class="text-start">
                  <v-icon dense> {{ siteContent.content_icon }} </v-icon
                  ><span class="text-align-middle mini"
                    >&nbsp;{{ siteContent.content_name }}</span
                  >
                </td>
                <td class="text-start">
                  <span class="text-subtitle-1">{{
                    siteContent.site_content_name
                  }}</span>
                </td>
                <td class="text-start">
                  <span
                    class="shop_site_span"
                    :style="
                      siteContent.color.length != 0
                        ? 'border:dashed 1px ' +
                          siteContent.color +
                          '; color:' +
                          siteContent.color
                        : ''
                    "
                    >{{ siteContent.site_name }}</span
                  >
                </td>
                <td class="text-start">
                  {{ siteContent.setting }}
                </td>
                <td class="text-start">
                  {{
                    siteContent.min_interval == 0
                      ? "-"
                      : siteContent.min_interval + "分"
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row align="center" justify="space-between">
            <v-col cols="6">
              <v-checkbox
                v-model="checkbox"
                label="セラピストとスケジュールを表示する"
                @change="onChange"
                hide-details="false"
              ></v-checkbox>
            </v-col>
            <v-col cols="6" sm="3">
              <general-button
                btn_color="gray"
                btn_block
                @click-event="cancelDialog"
                ><template v-slot:icon
                  ><v-icon left>mdi-close-circle</v-icon></template
                >キャンセル</general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  set,
  ref,
  reactive,
  toRefs,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";

export default defineComponent({
  setup(props, ctx) {
    const siteContentsRepository = repositoryFactory.get("siteContents");
    const state = reactive({
      siteContents: [],
      dialog: false, // ダイアログ表示用
      headers: [
        {
          text: "タイプ",
          value: "content_name",
          sortable: true,
          class: "td_content_name",
        },
        {
          text: "媒体コンテンツ",
          value: "site_content_name",
          sortable: true,
          class: "td_site_content_name",
        },
        {
          text: "更新媒体",
          value: "site_name",
          sortable: true,
          class: "td_site_name",
        },
        {
          text: "設定",
          value: "setting",
          sortable: true,
          class: "td_setting",
        },
        {
          text: "最低間隔",
          value: "min_interval",
          sortable: true,
          class: "td_min_interval",
        },
      ],
      search: "",
      checkbox: false,
    });

    // ダイアログ表示
    const showDialog = async () => {
      state.checkbox = false;
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示
      state.siteContents.splice(0);
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
      };
      await siteContentsRepository
        .list_shop(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.siteContents.push({
                "index": key,
                "site_content_id": response.data[key].id,
                "content_id": response.data[key].content_id,
                "tr_class": showContentCheck(
                  "content_id_" + response.data[key].content_id
                ),
                "content_format_ids": response.data[key].content_format_ids,
                "content_name": response.data[key].content_name,
                "content_icon": response.data[key].content_icon,
                "content_color": response.data[key].content_color,
                "site_content_name": response.data[key].name,
                "shop_site_id": response.data[key].shop_site_id,
                "site_name":
                  response.data[key].site_name +
                  (response.data[key].memo != null
                    ? "[" + response.data[key].memo + "]"
                    : ""),
                "color": response.data[key].color,
                "min_interval": response.data[key].min_interval,
                "update_content_id": response.data[key].update_content_id,
                "setting":
                  response.data[key].update_content_id != null
                    ? "設定あり"
                    : "--",
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:selectComponent.vue/showDialog siteContentsRepository.list_shop (" +
            error +
            ")"
          );
        });

      state.dialog = true; // ダイアログ開く
      await store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    const showContentCheck = (contentIdClass) => {
      let tr_class = "";
      // キャスト、スケジュール、求人はデフォルトでは表示しない
      if (
        contentIdClass == "content_id_1" ||
        contentIdClass == "content_id_2" ||
        contentIdClass == "content_id_9"
      ) {
        tr_class = "tr_hide";
      }
      return tr_class;
    };

    const onClickList = (siteContent) => {
      state.dialog = false;
      ctx.emit("selected", {
        shop_site_id: siteContent.shop_site_id,
        site_content_id: siteContent.site_content_id,
        update_content_id: siteContent.update_content_id,
      });
    };

    const onChange = () => {
      const elements = document.querySelectorAll(
        ".content_id_1, .content_id_2, .content_id_9"
      );
      if (state.checkbox) {
        elements.forEach((element) => {
          element.classList.remove("tr_hide");
        });
      } else {
        elements.forEach((element) => {
          element.classList.add("tr_hide");
        });
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      scrollReset();
      state.dialog = false;
    };

    const scrollReset = () => {
      setTimeout(() => {
        document.querySelector(".scrollable-content").scroll(0, 0);
      }, 200);
    };

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      cancelDialog,
      showDialog,
      onClickList,
      onChange,
      showContentCheck,
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}

::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_site_name {
  min-width: 200px;
}
::v-deep .td_content_name {
  min-width: 80px;
  width: 120px;
}
::v-deep .td_setting {
  min-width: 60px;
  width: 90px;
}
::v-deep .td_min_interval {
  min-width: 60px;
  width: 90px;
}

.shop_site_span {
  font-size: 0.75em;
  background-color: #fafafa;
  word-break: break-all;
}
.tr_hide {
  display: none;
}
</style>
